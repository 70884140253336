
import { defineComponent } from "vue";
import { Form, ErrorMessage } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "kt-widget-daily-cash-flow",
  components: {
    Form,
    ElCurrencyInputNoSymbol,
    // ErrorMessage,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    const openingBalances = {
      label: "Opening Balance",
      value: [],
    };

    const statements = {
      cashInFlow: {
        label: "Cash In Flow",
        child: {
          fromSales: {
            label: "From Sales",
            child: {
              cashSales: {
                label: "Cash Sales",
                value: [],
                total: 0,
              },
              cashAdvanceByCustomer: {
                label: "Cash Advance by Customers",
                value: [],
                total: 0,
              },
              cashbByTradeDebtors: {
                label: "Cash by Trade Debtors",
                value: [],
                total: 0,
              },
            },
          },
          fromFinancingCapital: {
            label: "From Financing/ Capital",
            child: {
              capitalContribution: {
                label: "Capital Contribution",
                value: [],
                total: 0,
              },
              loansGrantsReceivedfromBankGovernment: {
                label: "Loans/ Grants Received from Bank / Government",
                value: [],
                total: 0,
              },
              otherIncomes: {
                label: "Other Incomes / Cash Inflows",
                value: [],
                total: 0,
              },
            },
          },
        },
      },
      totalCashInFlow: {
        label: "Total Cash In Flow",
        value: [],
        total: 0,
      },
      cashOutFlow: {
        label: "Cash Outflow",
        child: {
          materialStockPurchased: {
            label: "Material/ Stock Purchased",
            value: [],
            total: 0,
          },
          marketing: {
            label: "Marketing",
            value: [],
            total: 0,
          },
          salariesEPFSOCSO: {
            label: "Salaries, EPF, SOCSO",
            value: [],
            total: 0,
          },
          rental: {
            label: "Rental",
            value: [],
            total: 0,
          },
          electricityWaterPhoneInternet: {
            label: "Electricity, Water, Phone, Internet",
            value: [],
            total: 0,
          },
          storageFeesDeliveryFees: {
            label: "Storage Fees/ Delivery Fees",
            value: [],
            total: 0,
          },
          generalExpensesOthers: {
            label: "General Expenses/ Others",
            value: [],
            total: 0,
          },
          insurance: {
            label: "Insurance",
            value: [],
            total: 0,
          },
          taxesZakat: {
            label: "Taxes/ Zakat",
            value: [],
            total: 0,
          },
          purchaseFixedAssets: {
            label:
              "Purchase of Fixed Assets (Restaurant Furnitures, Food Truck)",
            value: [],
            total: 0,
          },
          loans: {
            label:
              "Loans/ Grant Repayment (e.g. TEKUN, AIM, Microfinance, etc.)",
            value: [],
            total: 0,
          },
          personalUsageRepaymentofCapital: {
            label: "Personal Usage/ Repayment of Capital",
            value: [],
            total: 0,
          },
        },
      },
      totalCashOutFlow: {
        label: "Total Cash Out Flow",
        value: [],
        total: 0,
      },
    };

    const netCashSurpluses = {
      label: "Net Cash Surplus/ (Deficit)",
      total: 0,
      value: [],
    };

    const months = [
    	"Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const totalMonths = 0;

    return {
      months,
      statements,
      totalMonths,
      openingBalances,
      netCashSurpluses,
    };
  },
  async mounted() {
    // this.openingBalances.value[0] = 1;
    this.totalMonths = this.months.length;
    // this.statements.forEach((value, index) => {
    // }
    this.initDays();
  },
  methods: {
    initDays() {
      for (let index = 0; index < this.totalMonths; index++) {
        this.openingBalances.value.push(0);
        this.statements.totalCashInFlow.value.push(0);
        this.netCashSurpluses.value.push(0);
        this.statements.totalCashOutFlow.value.push(0);
      }
      // cash in flow
      for (let key of Object.keys(this.statements.cashInFlow.child)) {
        let cashInFlowChild = this.statements.cashInFlow.child[key].child;
        for (let keyChild of Object.keys(cashInFlowChild)) {
          for (let index = 0; index < this.totalMonths; index++) {
            cashInFlowChild[keyChild].value.push(0);
          }
        }
      }
      // cash out flow
      for (let key of Object.keys(this.statements.cashOutFlow.child)) {
        let cashOutFlowChild = this.statements.cashOutFlow.child[key];
        for (
          let indexcashOutFlowChild = 0;
          indexcashOutFlowChild < this.totalMonths;
          indexcashOutFlowChild++
        ) {
          cashOutFlowChild.value.push(0);
        }
      }
    },

    calculateOpeningBalance(index2) {
      // for (let i = 0; i < this.openingBalances.value.length; i++) {
      //   this.openingBalances.value[i] = 0;
      // }

      this.algorithFinancialStatement();
      // for (let i = 0; i < this.openingBalances.value.length; i++) {
      //   this.netCashSurpluses.value[i] =
      //     this.statements.totalCashInFlow.value[i] -
      //     this.statements.totalCashOutFlow.value[i];

      //   if (i > 0) {
      //     this.openingBalances.value[i] =
      //       this.netCashSurpluses.value[i - 1] +
      //       this.openingBalances.value[i - 1];
      //   }
      // }

    },
    algorithFinancialStatement(){

    for(let i = 0; i < this.totalMonths; i++ ){
        let totalCashInflow = 0;
        let totalCashOutFlow = 0;
        for (let keyChild of Object.keys(this.statements.cashInFlow.child)) {
          let cashInFlowChild = this.statements.cashInFlow.child[keyChild];
          for (let cashInFlowChildSub of Object.keys(cashInFlowChild.child)) {
            this.statements.cashInFlow.child[keyChild].child[cashInFlowChildSub].total = cashInFlowChild.child[cashInFlowChildSub].value.reduce((sum, item) => sum + item);
            totalCashInflow += cashInFlowChild.child[cashInFlowChildSub].value[i];
          }

        }

        for (let keyChild of Object.keys(this.statements.cashOutFlow.child)) {
          totalCashOutFlow += this.statements.cashOutFlow.child[keyChild].value[i];
        }
        this.statements.totalCashOutFlow.value[i] = totalCashOutFlow;
        this.statements.totalCashInFlow.value[i] = totalCashInflow + this.openingBalances.value[i];
        this.netCashSurpluses.value[i] = this.statements.totalCashInFlow.value[i] - this.statements.totalCashOutFlow.value[i]
        this.openingBalances.value[+i+1] = this.netCashSurpluses.value[i];
        this.netCashSurpluses.total = this.netCashSurpluses.value.reduce((sum, item) => sum + item);
        this.statements.totalCashInFlow.total = this.statements.totalCashInFlow.value.reduce((sum, item) => sum + item);
        this.statements.totalCashOutFlow.total = this.statements.totalCashOutFlow.value.reduce((sum, item) => sum + item);

        console.log(this.netCashSurpluses.total)





        // this.statements.totalCashInFlow.value[index] = total;
        // this.netCashSurpluses.value[index] = this.statements.totalCashInFlow.value[index] - this.statements.totalCashOutFlow.value[index]
        // this.openingBalances.value[+index+1] = this.netCashSurpluses.value[index];
    }
    },
    calculateNetCashSurpluses() {
      this.algorithFinancialStatement();
      // for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
      //   this.netCashSurpluses.value[i] = 0;
      // }

      // for (let keyChild of Object.keys(this.statements.cashOutFlow.child)) {
      //   let cashOutFlowChild = this.statements.cashOutFlow.child[keyChild];
      //   let stateChildSubValue = cashOutFlowChild.value;

      //   cashOutFlowChild.total = stateChildSubValue.reduce(
      //     (sum, item) => sum + item
      //   );

      //   for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
      //     this.netCashSurpluses.value[i] += stateChildSubValue[i];
      //     this.statements.totalCashOutFlow.value[i] =
      //       this.netCashSurpluses.value[i];

      //     this.statements.totalCashOutFlow.total = cashOutFlowChild.total;
      //   }
      // }

      // for (let i = 0; i < this.netCashSurpluses.value.length; i++) {
      //   this.netCashSurpluses.value[i] =
      //     this.statements.totalCashInFlow.value[i] -
      //     this.statements.totalCashOutFlow.value[i];

      //   if (i > 0) {
      //     this.openingBalances.value[i] =
      //       this.netCashSurpluses.value[i - 1] +
      //       this.openingBalances.value[i - 1];
      //   }
      // }

    },

    onSubmit(value) {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        console.log(value);
        // window.location.reload();
      });
    },
  },
});
